<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      Users
    </div>
    <div class="new_menu_button_sec">
      <el-button @click="openModal(null)">
        Add new user
      </el-button>
    </div>
  </div>
  <div>
    <div class="users_list row">
      <div v-for="(user, index) in this.$store.state.user.users" :key="index" class="user col-md-3">
        <div class="user_image">
          <img src="../../assets/img/avatar.png"/>
        </div>
        <div class="user_content">
          <h3 class="user-name">
            {{ user.name }}
          </h3>
          <div v-for="(role, index) in user.roles" :key="index">
            <p class="user-description">{{ role.name }}</p>
          </div>
          <el-button class="edit_button" @click="openModal(user.id)">
            Edit user
          </el-button>
          <el-button class="delete_user" @click="triggerUserPopup(user.id)">
            Delete user
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
      title="Add new user"
      v-model="dialogVisible"
      width="25%"
    >
     <NewUser :id="editID"></NewUser>
  </el-dialog>
  <el-dialog
    title="Confirm delete"
    v-model="confirmDelete"
    width="25%">
    Are you sure that you want to delete user?
    <el-button @click="deleteUser" class="button_style_2">
      DELETE USER
    </el-button>
  </el-dialog>

</template>

<script>

import NewUser from './NewUser'

export default {
  name: 'Users',
  components: {
    NewUser
  },
  data () {
    return {
      users: null,
      fullPage: false,
      editID: null,
      isLoading: true,
      dialogVisible: false,
      confirmDelete: false
    }
  },
  mounted () {
    const loader = this.$loading.show()
    this.$store.dispatch('fetchUsersData').then(() => {
      loader.hide()
    })
  },
  methods: {
    openModal (id) {
      this.dialogVisible = true
      this.editID = id
    },
    triggerUserPopup (id) {
      this.confirmDelete = true
      this.deleteID = id
    },
    deleteUser () {
      const loader = this.$loading.show()
      this.$store.dispatch('deleteUser', this.deleteID).then(() => {
        loader.hide()
      })
      this.confirmDelete = false
    }
  }
}
</script>

<style lang="scss" scoped>

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #42b983;
  }
}

.users_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}

.user {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .3);
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 23.6034%;

  .user_image {
    text-align: center;
    padding-bottom: 30px;

    img {
      max-width: 150px;
    }
  }

  .user_content {
    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: 30px;
    }

    button {
      background-color: #42b983;
      text-transform: uppercase;
      color: white;
      border: 1px solid #42b983;
      transition: all 0.4s ease;
    }

    button:hover {
      background-color: #fff;
      color: #42b983;
    }
  }

}

.user:nth-child(4) {
  margin-right: 0;
}

</style>
