import { createStore } from 'vuex'
import user from './modules/user'
import items from './modules/items'
import fakeNews from './modules/fake-news'

export default createStore({
  modules: {
    user,
    fakeNews,
    items
  }
})
