<template>
  <div class="menu-items">
    <div class="row">
      <div class="col-md-4">
        <h4 class="menu-items__h4">Add new item</h4>
        <el-tabs :tab-position="tabPosition" style="height: 200px;" v-model="typeItems" class="add_new_item_tabs">
          <el-tab-pane v-for="item in itemType" :name="item.slug" :label="item.name" :key="item.slug"
                       class="add_new_item_tab">
            <div v-if="typeItems !== ''">
              <div v-if="typeItems === 'external'">
                <el-input
                  placeholder="Title"
                  v-model="externalLink.name"
                  class="external_link_input"
                ></el-input>
                <el-input
                  placeholder="Url"
                  v-model="externalLink.url"
                  class="external_link_input"
                ></el-input>
                <el-button
                  @click="add(externalLink)"
                  class="button_style_1"
                >Add
                </el-button>
              </div>
              <div v-else>
                <div class="search_form">
                  <font-awesome-icon :icon="['fas', 'search']" class="search_icon"/>
                  <input type="text" @change="searchDelay" placeholder="Search" class="search_form_input">
                </div>
              </div>
            </div>
            <ul class="item_list">
              <li v-for="(item, index) in searchItem" :key="index" class="item_list__li">
                <div @click="add(item)" class="item_list__li__div">
                  <font-awesome-icon :icon="['fas', 'plus']" class="add_color"/>
                  <p>{{ item.name }}</p>
                </div>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="col-md-8">
        <h4 class="menu-items__h4">Menu items</h4>
        <draggable
          v-model="menuItems"
          group="people"
          @start="drag=true"
          @end="drag=false"
          item-key="idx">
          <template #item="{element, index}">
            <div>
              <el-collapse accordion v-model="activeName">
                <el-collapse-item>
                  <template #title>
                    <div class="item-title" @click="openItemPanel(element)">
                    <span class="handle dragg_icon">
                      <font-awesome-icon
                        :icon="['fas', 'bars']"
                        class="cursor_dragg_drop"/>
                    </span>
                      {{ element.name }}
                      <span class="remove_item">
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="cursor_pointer"
                        @click="removeAt(element, index)"/>
                      </span>
                    </div>
                  </template>
                  <p>Label: </p>
                  <el-input v-model="itemProperties.itemName"></el-input>
                  <div class="color_picker block">
                    <p>Chose color: </p>
                    <el-color-picker v-model="itemProperties.itemColor"></el-color-picker>
                  </div>
                  <el-button @click="updateItem(index)" class="update_item_button button_style_1">
                    Update
                  </el-button>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
        </draggable>
        <el-button @click="submit" class="button_style_1"> Save menu</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../services/api'
import draggable from 'vuedraggable'
import { getAllMenuItem, searchItem } from '../../services/menu'

export default {
  name: 'menu-items',
  components: {
    draggable
  },
  data () {
    return {
      menuItems: [],
      color: '#000000',
      activeName: '1',
      tabPosition: 'left',
      lang: 'en',
      id: null,
      search: '',
      dragging: true,
      typeItems: 'posts',
      searchItem: [],
      itemType: [
        {
          name: 'Post',
          slug: 'posts'
        },
        {
          name: 'Page',
          slug: 'pages'
        },
        {
          name: 'Category',
          slug: 'category'
        },
        {
          name: 'External link',
          slug: 'external'
        }
      ],
      deletedItems: [],
      itemProperties: {
        itemColor: '',
        itemName: '',
        itemIcon: ''
      },
      menu: false,
      externalLink: {
        name: '',
        relatedId: null,
        url: ''
      },
      loading: false,
      checkValidateIcon: false,
      loadingUpdate: false,
      checkUpdateIcon: false
    }
  },
  mounted () {
    this.id = this.$route.params.id
    const loader = this.$loading.show()
    getAllMenuItem(this.id).then(menuItems => {
      this.menuItems = menuItems
    })
    searchItem(this.typeItems, this.search).then(items => {
      items.map((item) => {
        if (this.typeItems === 'posts') {
          item.post_content.map(singlePost => {
            this.searchItem.push({
              name: singlePost.title,
              relatedId: singlePost.post_id
            })
          })
        } else if (this.typeItems === 'category') {
          item.category_content.map(singleCat => {
            this.searchItem.push({
              name: singleCat.name,
              relatedId: singleCat.category_id
            })
          })
        } else if (this.typeItems === 'pages') {
          item.page_content.map(singlePage => {
            this.searchItem.push({
              name: singlePage.title,
              relatedId: singlePage.page_id
            })
          })
        }
      })
      loader.hide()
    })
  },
  watch: {
    $route (to) {
      if (to.fullPath === '/menu/' + to.params.id) {
        const loader = this.$loading.show()
        getAllMenuItem(to.params.id).then(menuItems => {
          this.menuItems = menuItems
          loader.hide()
        })
      }
      this.id = to.params.id
    },
    checkTypeSearch () {
      this.searchItem = []
      if (this.lang !== undefined) {
        if (this.typeItems !== 'external') {
          const loader = this.$loading.show()
          searchItem(this.typeItems, this.search).then(items => {
            items.map((item) => {
              if (this.typeItems === 'posts') {
                item.post_content.map(singlePost => {
                  this.searchItem.push({
                    name: singlePost.title,
                    relatedId: singlePost.post_id
                  })
                })
              } else if (this.typeItems === 'category') {
                item.category_content.map(singleCat => {
                  this.searchItem.push({
                    name: singleCat.name,
                    relatedId: singleCat.category_id
                  })
                })
              } else if (this.typeItems === 'pages') {
                item.page_content.map(singlePage => {
                  this.searchItem.push({
                    name: singlePage.title,
                    relatedId: singlePage.page_id
                  })
                })
              }
            })
            loader.hide()
          })
        }
      }
    }
  },
  computed: {
    checkTypeSearch: function () {
      return [this.search, this.typeItems]
    }
  },
  methods: {
    removeAt (element, idx) {
      if (element.id) {
        this.deletedItems.push(element.id)
      }
      this.menuItems.splice(idx, 1)
    },
    checkMove: function (e) {
      window.console.log('Future index: ' + e.draggedContext.futureIndex)
    },
    add: function (item) {
      if (this.typeItems !== 'external') {
        this.menuItems.push({
          name: item.name,
          type: this.typeItems,
          related_id: item.relatedId,
          action: 'new_item',
          item_properties: {
            color: '#000000',
            icon: '',
            url: null
          }
        })
      } else {
        this.menuItems.push({
          name: this.externalLink.name,
          type: this.typeItems,
          related_id: null,
          action: 'new_item',
          item_properties: {
            color: '#000000',
            icon: '',
            url: this.externalLink.url
          }
        })
      }
    },

    selectItemType (itemType) {
      this.typeItems = itemType.slug
    },
    searchDelay (event) {
      this.search = event.target.value
    },
    submit () {
      const loader = this.$loading.show()
      api.post(`api/menu/${this.id}`, {
        lang: this.lang,
        menu_items: this.menuItems,
        deleted_items: this.deletedItems,
        menu_id: this.id,
        type: this.typeItems
      }).then(() => {
        this.menuItems.map((item) => {
          if ('action' in item) {
            item.action = ''
          }
        })
        loader.hide()
        this.$notify({
          title: 'Success',
          message: 'Your menu is successfully saved.',
          type: 'success'
        })
      }).catch((error) => {
        loader.hide()
        const objError = JSON.parse(error.request.response)
        let errorMessage
        if (objError.errors.name !== undefined) {
          errorMessage = objError.errors.name[0]
        } else {
          errorMessage = 'Server error! Please try later!'
        }
        this.$notify.error({
          title: 'Error',
          message: errorMessage,
          type: 'error'
        })
      })
    },
    openItemPanel (element) {
      this.itemProperties.itemName = element.name
      this.itemProperties.itemColor = element.item_properties.color
      this.itemProperties.itemIcon = element.item_properties.icon
    },
    updateItem (idx) {
      const itemObject = this.menuItems[idx]
      itemObject.name = this.itemProperties.itemName
      itemObject.item_properties.color = this.itemProperties.itemColor
      itemObject.item_properties.icon = this.itemProperties.itemIcon
    }
  }
}

</script>

<style lang="scss" scoped>

p {
  margin-bottom: 0;
}

.search_form {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f2f2f2;

  .search_icon {
    color: #42b983;
    position: absolute;
    left: 5px;
    font-size: 18px;
  }

  .search_form_input {
    width: 100%;
    border: none;
    margin-left: 30px;
  }

  .search_form_input:focus {
    border: none;
    outline: none;
  }
}

.item_list {
  padding: 0;
  list-style-type: none;

  .item_list__li p {
    margin-left: 15px;
    font-size: 17px;
  }

  .item_list__li__div {
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
    padding: 10px;
    border: 2px solid #f2f2f2;
    margin-bottom: 15px;
    border-radius: 5px;
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  .add_color {
    color: #42b983;
  }
}

.cursor_dragg_drop {
  cursor: move;

  .cursor_pointer {
    margin-left: auto;
    margin-right: 5px;
    color: red;
    cursor: pointer;
  }
}

.handle {
  margin-right: 10px;
}

.item-title {
  width: 100%;
}

.el-collapse-item:last-child {
  margin-bottom: 0;
}

.el-collapse {
  border: none;
  margin: 15px 0;
  position: relative;
  border: 2px solid #f2f2f2;
  padding: 0 15px;
  border-radius: 5px;

  .remove_item {
    position: absolute;
    right: -14px;
    top: -14px;
    z-index: 999999;
    width: 25px;
    height: 25px;
    background-color: #9c27b0;
    border-radius: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 15px;
      color: #fff;
      font-weight: 400;
    }
  }
}

.menu-items__h4 {
  margin-bottom: 20px;
}

.external_link_input {
  margin-bottom: 15px;
}

</style>
