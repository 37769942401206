<template>
  <div class="main-menu">
    <div class="navigation-menu">
      <ul>
        <li>
          <router-link :to="{
            name: 'Posts'
            }">
            <font-awesome-icon :icon="['fas', 'rocket']" class="nav_menu_icons"/>
              Prenete vesti
<!--            <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            </router-link>
        </li>
        <li>
          <router-link :to="{
            name: 'Fake-news'
            }">
            <font-awesome-icon :icon="['fas', 'rocket']" class="nav_menu_icons"/>
            Lazne vesti
            <!--            <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
          </router-link>
        </li>
<!--        <li>-->
<!--          <router-link :to="{-->
<!--            name: 'Posts'-->
<!--            }">-->
<!--            <font-awesome-icon :icon="['fas', 'newspaper']" class="nav_menu_icons"/>-->
<!--              Lažne vesti-->
<!--               <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </router-link>-->
<!--        </li>-->
      </ul>
      <div class="logout_button">
        <el-button type="warning" plain @click="logOutUser">Odjavi se</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NavigationMenu',
  methods: {
    logOutUser () {
      this.$cookies.remove('bearerToken')
      this.$store.dispatch('logOutUser')
      this.$router.push('/login')
    }
  }
}
</script>
<style>
.nav_menu_icons {
  margin-right: 5px;
  min-width: 30px;
}
</style>
