import axios from 'axios'
import app from '../main.js'

const api = axios.create({
  baseURL: process.env.VUE_APP_SHARED_API,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

api.interceptors.request.use(
  config => {
    const bearerToken = app.config.globalProperties.$cookies.get('bearerToken')
    config.headers.Authorization = 'Bearer ' + bearerToken // store.getters.user.token;
    return config
  }
)

export default api
