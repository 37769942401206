import api from './api'

export function getAllImages () {
  return api.get('api/media?per_page=50&type=image')
    .then((response) => {
      return response.data.data
    })
}

export function getAllDocuments () {
  return api.get('api/media?per_page=50&type=documents')
    .then((response) => {
      return response.data.data
    })
}

export function getAllAudio () {
  return api.get('api/media?per_page=50&type=audio')
    .then((response) => {
      return response.data.data
    })
}
