<template>
  <div class="image_selector_input" v-if="mutableImageSlug || featuredImageSlug">
    <div v-if="multiple" class="preview-single__gallery">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="featured_image_single">
        <img :src="image.src" :alt="image.alt">
        <p class="remove_image_from_gallery"
           @click="removeImageFromGallery(image.id)">
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="cursor_pointer"/>
        </p>
      </div>
    </div>
    <div v-else-if="mutableImageSlug !== 'empty'">
      <div v-if="mutableImageSlug !== 'empty'">
        <img :src="mutableImageSlug" alt="" class="featured_image_single">
      </div>
      <div v-else-if="featuredImageSlugData !=='empty'">
        <img :src="featuredImageSlugData" alt="" class="featured_image_single">
      </div>
    </div>
    <div v-if="multiple">
      <file-selector :fileSelect="fileSelect" mediaType='gallery' class="file_select"/>
    </div>
    <div v-else>
      <file-selector :fileSelect="fileSelect" mediaType='image' class="file_select"/>
      <el-button
      @click="removeFeaturedImage"
      class="button_style_2"
      v-if="this.mutableImageSlug !== 'empty' && this.multiple === false"> Remove featured image
    </el-button>
    </div>
  </div>
</template>

<script>
import FileSelector from './FileSelector'

export default {
  name: 'ImageSelectorInput',
  components: { FileSelector },
  data () {
    return {
      images: [],
      mutableImageSlug: '',
      featuredImageSlugData: this.featuredImageSlug
    }
  },
  props: {
    multiple: Boolean,
    gallery: Array,
    featuredImageSlug: String
  },
  methods: {
    fileSelect (file) {
      if (this.multiple === true) {
        this.images = file
        const galleryArray = []
        file.map(singleFile => {
          galleryArray.push(singleFile.id)
        })
        this.$emit('update:modelValue', galleryArray)
      } else {
        this.mutableImageSlug = file.src
        this.$emit('update:modelValue', file.id)
      }
    },
    removeFeaturedImage () {
      this.mutableImageSlug = 'empty'
      this.featuredImageSlugData = 'empty'
      this.$emit('update:modelValue', 0)
    },
    removeImageFromGallery (id) {
      this.images.map((singleImage, index) => {
        if (singleImage.id === id) {
          this.images.splice(index, 1)
          const deletedArray = []
          this.images.map(singleImage2 => {
            deletedArray.push(singleImage2.id)
          })
          this.$emit('update:modelValue', deletedArray)
        }
      })
    }
  },
  mounted () {
    this.mutableImageSlug = this.featuredImageSlug
    if (this.gallery) {
      this.images = this.gallery
    }
  },
  watch: {
    $route (to) {
      if (this.featuredImageSlugData !== 'empty') {
        this.featuredImageSlugData = 'empty'
        this.mutableImageSlug = 'empty'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.featured_image_single {
  margin-bottom: 20px;
}

.file_select {
  display: inline-block;
}

.image_selector_input .button_style_2{
  margin-left: 15px;
}

.preview-single__gallery {
  display: flex;
  flex-wrap: wrap;

  .featured_image_single {
    flex: 0 0 29%;
    max-width: 29%;
    display: flex;
    margin: 0 10px;
    position: relative;

    .remove_image_from_gallery {
      position: absolute;
      right: -10px;
      cursor: pointer;
      top: -10px;
      background-color: #9c27b0;
      color: white;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    img {
      margin-bottom: 30px;
    }
  }

  .featured_image_single:first-child {
    margin-left: 0;
  }

  .featured_image_single:last-child {
    margin-right: 0;
  }
}
</style>
