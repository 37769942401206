<template>
  <div class="pagination_links">
    <div class="demo-pagination-block">
      <el-pagination
        v-model:currentPage="$store.state.items.currentPage"
        :page-size="50"
        layout="prev, pager, next, jumper"
        :total="totalPosts"
        @current-change="fetchWithPagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: [
    'type', 'partner', 'date', 'view_sort', 'transmission_sort', 'current_page', 'search', 'emotionalScoreSort', 'dateSort'
  ],
  data: () => ({
    paginationLinks: [],
    totalPosts: 0
  }),
  methods: {
    fetchWithPagination (page) {
      const loader = this.$loading.show()
      if (this.type !== 'fake-news') {
        this.$store.dispatch('fetchData', {
          type: this.type,
          partner: this.partner,
          date: this.date,
          sort_views: this.view_sort,
          sort_transmission: this.transmission_sort,
          page: page
        }).then(results => {
          this.paginationLinks = []
          this.totalPosts = this.$store.state.items.paginationLinks.total
          this.$store.state.items.paginationLinks.links.map(singleLink => {
            if (parseInt(singleLink.label)) {
              this.paginationLinks.push({
                page: singleLink.label,
                active: singleLink.active
              })
            }
          })
          loader.hide()
        }
        )
      } else {
        this.$store.dispatch('fetchFakeNews', {
          type: 'fake-news',
          page: page,
          partner: this.partner,
          date: this.date,
          sortDate: this.dateSort,
          sortScore: this.emotionalScoreSort,
          search: this.search
        }).then(results => {
          this.totalPosts = this.$store.state.items.paginationLinks.total
          this.$store.state.items.paginationLinks.links.map(singleLink => {
            if (parseInt(singleLink.label)) {
              this.paginationLinks.push({
                page: singleLink.label,
                active: singleLink.active
              })
            }
          })
          loader.hide()
        })
      }
    }
  },
  mounted () {
    if (this.type !== 'fake-news') {
      this.$store.dispatch('fetchData', {
        type: this.type,
        partner: this.partner,
        date: this.date
      }).then(results => {
        this.totalPosts = this.$store.state.items.paginationLinks.total
        this.$store.state.items.paginationLinks.links.map(singleLink => {
          if (parseInt(singleLink.label)) {
            this.paginationLinks.push({
              page: singleLink.label,
              active: singleLink.active
            })
          }
        })
      }
      )
    } else {
      this.$store.dispatch('fetchFakeNews', {
        type: 'fake-news',
        page: 1,
        partner: this.partner,
        date: this.date,
        sortDate: this.dateSort,
        sortScore: this.emotionalScoreSort,
        search: this.search
      }).then(results => {
        this.totalPosts = this.$store.state.items.paginationLinks.total
        this.$store.state.items.paginationLinks.links.map(singleLink => {
          if (parseInt(singleLink.label)) {
            this.paginationLinks.push({
              page: singleLink.label,
              active: singleLink.active
            })
          }
        })
      })
    }
  },
  computed: {
    totalPostsComputed () {
      if (this.$store.state.items.paginationLinks) {
        return this.$store.state.items.paginationLinks.total
      }
      return false
    }
  },
  watch: {
    totalPostsComputed (newValue, oldValue) {
      this.totalPosts = newValue
    }
  }
}

</script>

<style lang="scss">

.pagination_links {
  text-align: right;
  margin-top: 15px;
  .pagination_links_div {
    display: inline-block;
    margin: 0 5px;

    .pagination_link {
      display: inline-block;
      padding: 2px 15px;
      cursor: pointer;
      font-weight: 500;
      color: #42b983;
      border: 2px solid #42b983;
      border-radius: 3px;
      text-decoration: none;
    }
    .pagination_link.active {
      color: white;
      background-color: #42b983;
    }
  }
}

.pagination_links_div:last-child {
  margin-right: 0;
}

.demo-pagination-block {
  margin-top: 20px;
  margin-bottom: 10px;
}

</style>
