import api from './api'

export function getAllMenus () {
  return api.get('api/menu')
    .then((res) => {
      return res.data
    })
}

export function getAllMenuItem (id) {
  return api.get(`api/menu/${id}`)
    .then((res) => {
      return res.data.menu_items
    })
}

export function searchItem (type, search) {
  return api.get(`api/${type}?per_page=10&search=${search}`)
    .then((res) => {
      return res.data.data
    })
}

export function addNewMenu (name) {
  return api.post('api/menu', {
    name: name
  })
}
