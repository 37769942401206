<template>
  <div class="new_user">
    <div class="new_user_body">
      <div class="new_user_body_image">
        <img src="../../assets/img/avatar.png" alt="User Image" class="user_avatar"/>
      </div>
      <div class="new_user_form">
        <form @submit.prevent="submit">
          <el-input placeholder="Name*" v-model="userData.name"></el-input>
          <el-input placeholder="Email*" v-model="userData.email"></el-input>
          <div class="new_user_form__passwords" v-if="id === null">
            <el-input placeholder="Password*" v-model="userData.password" type="password"></el-input>
            <el-input placeholder="Repeat Password*" v-model="userData.repeatPassword" type="password"></el-input>
          </div>
          <el-select v-model="userData.roleID" placeholder="Select role">
            <el-option
              v-for="item in userData.roles"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            @click="submit(id)"
            v-if="id === null"
            class="button_style_1">
            ADD USER
          </el-button>
          <el-button
            v-else
            @click="submit(id)"
            class="button_style_1">
            EDIT USER
          </el-button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../services/api'
import { watch } from 'vue'

export default {
  name: 'New User',
  props: {
    id: null
  },
  data () {
    return {
      userData: {
        name: null,
        email: null,
        password: null,
        repeatPassword: null,
        roles: [
          {
            id: 1,
            name: 'Administrator'
          },
          {
            id: 2,
            name: 'Assistant Manager'
          }
        ],
        roleID: null
      }
    }
  },
  mounted () {
    if (this.id) {
      const loader = this.$loading.show()
      api.get('api/auth/users/' + this.id).then(response => {
        this.userData.name = response.data.name
        this.userData.email = response.data.email
        response.data.roles.map(role => {
          this.userData.roleID = role.id
        })
        loader.hide()
      })
    }
    watch(() => this.id, (first) => {
      if (first !== null) {
        const loader = this.$loading.show()
        api.get('api/auth/users/' + first).then(response => {
          this.userData.name = response.data.name
          this.userData.email = response.data.email
          response.data.roles.map(role => {
            this.userData.roleID = role.id
          })
          loader.hide()
        })
      } else {
        this.userData.name = ''
        this.userData.email = ''
        this.userData.roleID = ''
      }
    })
  },
  methods: {
    submit (id) {
      if (this.userData.password === this.userData.repeatPassword) {
        this.userData.password = this.userData.repeatPassword
      } else {
        this.password = null
      }
      var user = {
        id: id,
        name: this.userData.name,
        email: this.userData.email,
        password: this.userData.password,
        role_id: this.userData.roleID
      }
      const loader = this.$loading.show()
      this.$store.dispatch('addUser', user).then((results) => {
        loader.hide()
        if (results.request.status === 422) {
          const objError = JSON.parse(results.request.response)
          console.log(objError.errors)
          let errorMessage
          if (objError.errors.name !== undefined) {
            errorMessage = 'Name field is required and need to be a string!'
          } else if (objError.errors.email !== undefined) {
            errorMessage = objError.errors.email[0]
          } else if (objError.errors.password !== undefined) {
            errorMessage = objError.errors.password[0]
          }
          this.$notify.error({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          })
        } else if (results.request.status === 200) {
          this.$notify({
            title: 'Success',
            message: 'User is successfully added!',
            type: 'success'
          })
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Server error! Please try later!',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.new_user_body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  .user_avatar {
    max-width: 200px;
    margin-bottom: 30px;
  }

  .el-input {
    margin-bottom: 15px;
  }

  .el-select {
    margin-bottom: 15px;
  }
}
</style>
