import { createApp } from 'vue'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.scss'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import VueCookies from 'vue3-cookies'
import VueLoading from 'vue-loading-overlay'
import moment from 'moment'

library.add(fas)
library.add(fab)
library.add(far)
dom.watch()
const app = createApp(App)

app.config.globalProperties.$moment = moment

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(store).use(router).use(ElementPlus).use(VueLoading, {
  // Optional parameters
  loader: 'bars',
  backgroundColor: '#000',
  height: 75,
  width: 75,
  opacity: 0.5,
  color: '#42b983'
}).use(VueCookies)
export default app
app.mount('#app')
