<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      {{ props.menuName }}
    </div>
  </div>
  <div class="filter_section">
    <div class="partners_filter">
      <p>Partneri:</p>
      <el-select v-model="partner" placeholder="Select" @change="triggerFetchData(partner, date)">
        <el-option
          :key="'Svi'"
          :label="'Svi'"
          :value="'Svi'"
        >
        </el-option>
        <el-option
          v-for="partner in partners"
          :key="partner"
          :label="partner"
          :value="partner"
        >
        </el-option>
      </el-select>
    </div>
    <div class="date_filter">
      <div class="block">
        <p>Datum:</p>
        <el-date-picker
          v-model="date"
          type="daterange"
          unlink-panels
          format="DD MMM YYYY"
          value-format="YYYY-MM-DD"
          start-placeholder="Od dana"
          end-placeholder="Do dana"
          :shortcuts="shortcuts"
          @change="triggerFetchData(partner, date)"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="filter_search">
      <p>Pretraga:</p>
      <div class="search">
        <el-input v-model="search" placeholder="Please input" />
        <el-button @click="searchFilter()" class="button_style_2 search_btn">Search</el-button>
      </div>
    </div>
    <div class="default_filter">
      <el-button @click="setDefaultOrder()" class="button_style_2">Resetuj Filtere</el-button>
    </div>
  </div>
  <div class="items">
    <div class="row items_form_title">
      <div class="post_counter">#</div>
      <div v-for="(value, index) in propsTitle" :key="index"
           class="items_form_title__columns" :class="'col-md-' + value.columns">
        {{ value.name }}
        <span v-if="value.orderBy" @click="setOrderDirection(value.orderBy)">
          <i class="fas fa-sort"></i>
        </span>
      </div>
    </div>
    <div v-if="Object.keys(props).length">
      <div class="row item_list" v-if="Object.keys(props).length !== 0">
        <div class="row single_row_item" v-for="(item, index) in $store.state.fakeNews.fakeNews" :key="index">
          <div class="post_counter item_title">
            {{ ($store.state.items.currentPage - 1) * 50 + index + 1 }}.
          </div>
          <div
            v-if="props.title.show === true"
            class="item_title"
            :class="'col-md-' + props.title.columns">
            <a :href="item.post_url" target="_blank">
              <h5>{{ item.post_title }}</h5>
              <font-awesome-icon :icon="['fas', 'external-link-alt']" class="nav_menu_icons"/>
            </a>
          </div>
          <div
            v-if="props.source.show === true"
            class="item_title"
            :class="'col-md-' + props.source.columns">
            {{ item.source }}
          </div>
          <div
            v-if="props.created_at.show === true"
            class="item_title"
            :class="'col-md-' + props.created_at.columns">
            {{ $moment(item.created_at).format('DD MMM YYYY') }}
          </div>
          <div
            v-if="props.emotional_score"
            class="item_title"
            :class="'col-md-' + props.emotional_score.columns">
            {{ Number(item.compound_score).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <Pagination
      type="fake-news"
      :partner="partner"
      :date="date"
      :page="current_page"
      :search="search"
      :dateSort="dateSort"
      :emotionalScoreSort="emotionalScoreSort"></Pagination>
  </div>
</template>

<script>

import { fakeNewsObject } from '@/helpers/config'
import Pagination from '@/views/Pagination'
import { reactive, toRefs } from 'vue'

export default {
  name: 'FakeNews',
  components: {
    Pagination
  },
  props: ['singleObj'],
  setup () {
    const state = reactive({
      shortcuts: [
        {
          text: 'Prošla nedelje',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setDate(start.getDate() - 6)
            end.setDate(end.getDate() + 1)
            return [start, end]
          }
        },
        {
          text: 'Prošli mesec',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setDate(start.getDate() - 29)
            end.setDate(end.getDate() + 1)
            return [start, end]
          }
        },
        {
          text: 'Prošlih 3 meseca',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setDate(start.getDate() - 89)
            end.setDate(end.getDate() + 1)
            return [start, end]
          }
        }
      ],
      value1: '',
      value2: ''
    })

    return {
      ...toRefs(state)
    }
  },
  data () {
    return {
      props: {},
      date: null,
      search: '',
      partner: 'Svi',
      emotionalScoreSort: '',
      dateSort: '',
      partners: [
        'Alo',
        'Blic',
        'Direktno',
        'Espreso',
        'Happy',
        'Info KS',
        'Informer',
        'Kurir',
        'Novosti',
        'Pink',
        'Srbija Danas',
        'Telegraf'
      ],
      current_page: 1,
      propsTitle: []
    }
  },
  methods: {
    setOrderDirection (type) {
      const loader = this.$loading.show()
      if (type === 'emotional_score_sort') {
        if (this.emotionalScoreSort === 'desc') {
          this.emotionalScoreSort = 'asc'
        } else {
          this.emotionalScoreSort = 'desc'
        }
      } else if (type === 'date_sort') {
        if (this.dateSort === 'desc') {
          this.dateSort = 'asc'
        } else {
          this.dateSort = 'desc'
        }
      }

      this.$store.dispatch('fetchFakeNews', {
        type: 'fake-news',
        sortDate: this.dateSort,
        sortScore: this.emotionalScoreSort,
        partner: this.partner,
        page: this.page,
        date: this.date,
        search: this.search
      }).then(() => {
        loader.hide()
      })
      loader.hide()
    },
    setDefaultOrder () {
      const loader = this.$loading.show()
      this.partner = 'Svi'
      var start = new Date()
      var end = new Date()
      start.setDate(start.getDate() - 15)
      end.setDate(end.getDate() + 15)
      var startFormat = this.$moment(start).format('YYYY-MM-DD')
      var endFormat = this.$moment(end).format('YYYY-MM-DD')
      this.date = [startFormat, endFormat]
      this.sort_views = 'undefined'
      this.dateSort = ''
      this.emotionalScoreSort = ''
      this.$store.state.items.currentPage = 1
      this.search = ''
      this.$store.dispatch('fetchFakeNews', {
        type: 'fake-news',
        partner: 'Svi',
        date: this.date,
        page: 1,
        sortDate: this.dateSort,
        sortScore: this.emotionalScoreSort,
        search: this.search
      }).then(() => {
        loader.hide()
      })
    },
    searchFilter () {
      const loader = this.$loading.show()
      this.$store.dispatch('fetchFakeNews', {
        type: 'fake-news',
        partner: this.partner,
        date: this.date,
        page: 1,
        sortDate: this.dateSort,
        sortScore: this.emotionalScoreSort,
        search: this.search
      }).then(() => {
        loader.hide()
      })
    },
    triggerFetchData (partner, date) {
      const loader = this.$loading.show()
      this.$store.dispatch('fetchFakeNews', {
        type: 'fake-news',
        partner: partner,
        date: date,
        sortDate: this.dateSort,
        sortScore: this.emotionalScoreSort,
        search: this.search
      }).then(() => {
        loader.hide()
      })
    }
  },
  mounted () {
    var start = new Date()
    var end = new Date()
    start.setDate(start.getDate() - 15)
    end.setDate(end.getDate() + 15)
    var startFormat = this.$moment(start).format('YYYY-MM-DD')
    var endFormat = this.$moment(end).format('YYYY-MM-DD')
    this.date = [startFormat, endFormat]
    this.$store.dispatch('fetchFakeNews', {
      type: 'fake-news',
      partner: this.partner,
      date: this.date,
      sortDate: this.dateSort,
      sortScore: this.emotionalScoreSort,
      search: this.search
    })
    let propsObj = null
    propsObj = fakeNewsObject()
    Object.assign(this.props, propsObj)
    for (const key in propsObj) {
      if (propsObj[key].show === true) {
        var singleObj = {}
        singleObj = propsObj[key]
        this.propsTitle.push(singleObj)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.filter_section {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: left;
  margin-bottom: 30px;

  .partners_filter {
    text-align: left;
    margin-right: 30px;

    p {
      margin-bottom: 10px;
    }
  }

  .date_filter {
    text-align: left;
    margin-left: 30px;

    p {
      margin-bottom: 10px;
    }
  }

  .default_filter {
    position: absolute;
    right: 30px;
    bottom: 0;
  }

  .filter_search{
    text-align: left;
    margin-left: 30px;

    p {
      margin-bottom: 10px;
    }

    .search{
      display: flex;
      align-items: center;

      .search_btn{
        margin-left: 10px;
      }
    }
  }
}

.post_counter {
  text-align: left;
  width: 30px;
  flex: 0 0 30px;
  padding-left: 0;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;

    a {
      display: inline-block;
      background-color: transparent;
      color: #42b983;
      border: 1px solid #42b983;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.item_title {
  position: relative;
  z-index: 9999;
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #42b983;
  }
}

.items {
  padding-right: 30px;

  .items_form_title {
    border-radius: 5px;
    background-color: #42b983;
    color: white;
    padding: 5px 25px;
    text-align: left;
    align-items: center;
    justify-content: space-between;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    border: 2px solid #42b983;
    border-top: none;
    margin-top: -25px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      position: relative;
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 25px;
      margin: 0;
      justify-content: space-between;

      .item_title {
        padding: 0;
        padding-right: 15px;
      }

      h5 {
        display: inline;
        font-weight: normal;
        line-height: 30px;
        margin-bottom: 0;
        color: #42b983;
        text-decoration: none;
      }

      a {
        color: #42b983;
        display: inline-block;
        text-decoration: none;
        max-width: 95%;
      }

      .single_row_item__p {
        color: #42b983;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #42b983;
          text-decoration: none;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #42b983;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .overlay.greenBg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(66, 185, 131, 0.3);
      z-index: 9;
      margin-left: -25px;
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}

</style>
